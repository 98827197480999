import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Grid } from "semantic-ui-react"
import { getCourses } from "../../../store/actions/courseActions"
import Tile from "../../common/Tile"
import "./index.scss"

const Courses = ({ courses, getCourses }) => {
	useEffect(() => {
		getCourses()
	}, [])

	if (courses.isFetching) return null

	return (
		<div className="Courses tile-wrapper">
			<h1 className="screen-header">Your Courses...</h1>
			<Grid centered>
				{courses.isFetching
					? null
					: courses.data.map(item => (
							<Grid.Column key={item.id} computer={5} mobile={16}>
								<Link to={`/course/${item.id}`}>
									<Tile
										firstColor={item.tint_color}
										secondColor={item.tint_color_second}
										textColor={item.text_colour}
										title={item.name}
										bgImage={item.background}
									/>
								</Link>
							</Grid.Column>
					  ))}
			</Grid>
		</div>
	)
}

export default connect(
	state => ({
		courses: state.courseReducer,
	}),
	{
		getCourses,
	},
)(Courses)
