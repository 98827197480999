import React from "react"
import "../index.scss"
import { Link } from "react-router-dom"
import { PropTypes } from "prop-types"
import { formatPrice } from "../../../../utils/money"

const ProductItem = ({ id, name, price, description }) => {
	return (
		<div className="product-card">
			<div className="product-card-header">
				<div className="title">{name}</div>
				<div className="price">{formatPrice(price)}</div>
			</div>
			<div className="product-card-footer">
				<div dangerouslySetInnerHTML={{ __html: description }} />
				<Link className="btn btn-secondary" to={"/upgrade/" + id}>
					Buy now
				</Link>
			</div>
		</div>
	)
}

ProductItem.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	price: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
}

export default ProductItem
