import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import { connect } from "react-redux"
import { PropTypes } from "prop-types"
import { toggleShowUpgradeSuccessfulPage } from "../../../../store/actions/authActions"
import "./index.scss"

const Congrats = ({ toggleShowUpgradeSuccessfulPage }) => {
	useEffect(() => () => toggleShowUpgradeSuccessfulPage(), [])

	return (
		<div className="Upgrade Congrats">
			<Icon name="check" size="huge" />
			<h1>Congratulations!</h1>
			<p>Thank you. You are now ready to enjoy Wicked Maths to the full.</p>
			<Link to="/" className="btn btn-primary-gradient">
				Lets go
			</Link>
		</div>
	)
}

Congrats.propTypes = {
	toggleShowUpgradeSuccessfulPage: PropTypes.func.isRequired,
}

export default connect(
	null,
	{ toggleShowUpgradeSuccessfulPage },
)(Congrats)
