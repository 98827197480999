import React, { useMemo } from "react"
import "./index.scss"
import calculatorIcon from "../../../static/img/calculator.png"
import noCalculatorIcon from "../../../static/img/no-calculator.png"
import graduationCap from "../../../static/img/graduation_cap.png"
import { CALCULATOR_ALLOWED, CALCULATOR_HIDDEN } from "../../../consts/lessons"

const Tile = ({
	title,
	subtitle,
	disabled,
	firstColor,
	secondColor,
	textColor,
	grouped,
	calculator,
	level,
	bgImage,
}) => {
	firstColor = firstColor ? firstColor : !grouped ? "#690986" : "#690986"
	secondColor = secondColor ? secondColor : !grouped ? "#690986" : "#690986"
	textColor = textColor ? textColor : "#ffffff"

	const styles = useMemo(() => {
		const result = {}

		if (bgImage) {
			result.backgroundImage = `url("${bgImage}")`
		}

		return result
	})

	const isCalculatorShown = Boolean(calculator && calculator !== CALCULATOR_HIDDEN)

	return (
		<div className={`Tile ${disabled ? "disabled" : ""}`} style={styles}>
			<div
				className="gradient"
				style={{
					background: `linear-gradient(270deg, ${secondColor} 0%, ${firstColor} 100%)`,
				}}
			/>
			{isCalculatorShown && (
				<img
					className={`calculator-image ${calculator === CALCULATOR_ALLOWED ? "allow" : "disallow"}`}
					src={calculator === CALCULATOR_ALLOWED ? calculatorIcon : noCalculatorIcon}
					alt="calculator"
				/>
			)}
			{level === "higher only" ? (
				<img className="graduation-cap" src={graduationCap} alt="graduation cap" />
			) : null}
			<div
				className="text"
				style={{
					color: textColor,
				}}
			>
				<h2>{title}</h2>
				{subtitle && <h3>{subtitle}</h3>}
			</div>
		</div>
	)
}

export default Tile
