import React, { useState, useEffect } from "react"
import { reduxForm, SubmissionError } from "redux-form"
import { Button, Grid } from "semantic-ui-react"
import { PropTypes } from "prop-types"
import { compose } from "redux"
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import PaymentForm from "../../../common/PaymentForm"
import { upgradeTrial } from "../../../../domain/payment"
import { setUser, toggleShowUpgradeSuccessfulPage } from "../../../../store/actions/authActions"
import PaymentSummaryBox from "../../../common/PaymentSummaryBox"
import { getProductForPurchase } from "../../../../store/actions/productActions"
import "../index.scss"

const formName = "upgradeForm"

const Form = ({ handleSubmit, match, history, setUser, toggleShowUpgradeSuccessfulPage, getProductForPurchase }) => {
	const [loading, setLoading] = useState(false)
	const productId = match.params.id

	const elements = useElements()
	const stripe = useStripe()

	useEffect(() => {
		getProductForPurchase(productId)
	}, [])

	const submit = async data => {
		setLoading(true)

		const cardElement = elements.getElement(CardNumberElement)

		const paymentMethod = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,
		})

		if (paymentMethod.error) {
			setLoading(false)
			throw new SubmissionError({
				payment: paymentMethod.error.message,
			})
		}

		const {
			paymentMethod: { id },
		} = paymentMethod

		data.payment = { id }

		return upgradeTrial(productId, data)
			.then(res => {
				const data = res.data.data

				toggleShowUpgradeSuccessfulPage(data)
				setUser(data)
				history.push("/upgrade/congrats")

				return data
			})
			.catch(err => {
				throw new SubmissionError(err.response.data.errors)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	return (
		<Grid reversed="computer" verticalAlign="middle">
			<Grid.Column mobile={16} computer={5}>
				<PaymentSummaryBox />
			</Grid.Column>
			<Grid.Column mobile={16} computer={11}>
				<form className="form" onSubmit={handleSubmit(submit)}>
					<PaymentForm form={formName} title="Payment details" />
					<Button type="submit" loading={loading} className="btn btn-primary-gradient">
						Pay now
					</Button>
				</form>
			</Grid.Column>
		</Grid>
	)
}

Form.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	toggleShowUpgradeSuccessfulPage: PropTypes.func.isRequired,
}

export default compose(
	connect(
		null,
		{ setUser, toggleShowUpgradeSuccessfulPage, getProductForPurchase },
	),
	reduxForm({ form: formName }),
	withRouter,
)(Form)
