import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Field } from "redux-form"
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js"
import { acceptance } from "redux-form-validators"
import _ from "lodash"
import { Checkbox, Error, SInput } from "../../helpers/fields"
import VisaLog from "../../../static/img/visa-logo.png"
import MasterCard from "../../../static/img/mastercard-logo.png"
import AmericanExpress from "../../../static/img/american-express-logo.png"
import { getDiscountData, resetDiscountData } from "../../../store/actions/productActions"
import "./index.scss"

const PaymentForm = ({ form, title }) => {
	const [discountChecking, setDiscountChecking] = useState(false)
	const discount = useSelector(state => state.productReducer.discount)
	const product = useSelector(state => state.productReducer.productForPurchase)
	const dispatch = useDispatch()

	const checkDiscount = useCallback(
		_.debounce((e, value) => {
			if (!value) {
				return
			}

			setDiscountChecking(true)
			dispatch(getDiscountData(product.id, value)).finally(() => {
				setDiscountChecking(false)
			})
		}, 500),
		[form, product],
	)

	const validateDiscount = useCallback(
		value => {
			if (value && discount.error) {
				return discount.error
			}
		},
		[discount],
	)

	useEffect(
		() => () => {
			dispatch(resetDiscountData())
		},
		[],
	)

	return (
		<div className="PaymentForm">
			<h3>{title}</h3>
			<div className="payment-symbols">
				<img src={VisaLog} alt="visa logo" />
				<img src={MasterCard} alt="mastercard logo" />
				<img src={AmericanExpress} alt="american express logo" />
			</div>
			<Field name="payment" component={Error} />
			<div className="input-wrapper">
				<label>Card Number</label>
				<CardNumberElement />
			</div>
			<div className="row">
				<div className="input-wrapper">
					<label>Card Expiry</label>
					<CardExpiryElement />
				</div>
				<div className="input-wrapper">
					<label>CVC</label>
					<CardCvcElement />
				</div>
			</div>
			<Field
				name="discount_code"
				component={SInput}
				onChange={checkDiscount}
				label="Discount Code"
				validate={validateDiscount}
				fluid
				loading={discountChecking}
			/>
			<Field
				name="terms"
				component={Checkbox}
				label="Please tick this box if you have read and accepted our Terms and Conditions and Privacy Policy"
				validate={[acceptance({ accept: true })]}
			/>
		</div>
	)
}

export default PaymentForm
