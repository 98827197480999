import React, { useEffect, useState } from "react"
import "./index.scss"
import MathJax from "react-mathjax-preview"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Checkbox } from "semantic-ui-react"
import { clearScripts } from "../../../utils/mathJax"
import { toggleDone, getStandard } from "../../../store/actions/lessonActions"
import { getNextSectionLink } from "../../../domain/lesson"

const QuestionItem = ({
	title,
	question,
	questionsCount,
	lessonId,
	demo,
	toggleDone,
	doneQuestionCount,
	getStandard,
}) => {
	const [openAnswer, setOpenAnswer] = useState(demo ? Boolean(doneQuestionCount) : question.done)
	const [nextSectionLinkData, nextSectionLinkDataSet] = useState({ data: {} })

	useEffect(() => {
		if (doneQuestionCount) getStandard(lessonId)
		if (demo)
			getNextSectionLink(lessonId).then(res => {
				nextSectionLinkDataSet(res.data)
			})
	}, [])

	useEffect(() => {
		clearScripts()
	})

	return (
		<div className={`QuestionItem ${demo ? "demo" : ""} ${openAnswer ? "revealed" : ""}`}>
			<h2>{title}</h2>
			<div className="content">
				<MathJax math={question.question} />
				{question.question_image ? <img src={question.question_image} alt="question" /> : null}
			</div>
			<div className="answer-wrap">
				{!openAnswer ? (
					<div className="overlay">
						<button
							type="button"
							className="btn btn-secondary reveal-answer"
							onClick={() => setOpenAnswer(true)}
						>
							<span>Reveal answer</span>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595.28 841.89">
								{/* eslint-disable-next-line max-len */}
								<path d="M297.914 449.41c-42.606 18.525-92.162-.996-110.688-43.601-18.525-42.605.996-92.161 43.601-110.688 42.606-18.525 92.162.996 110.688 43.601 18.524 42.607-.996 92.163-43.601 110.688m94.532 80.494c-6.722-11.643-19.284-32.832-26.005-44.475-5.766-9.984-16.756-15.066-27.523-13.799-3.045-5.271-6.149-10.65-8.008-13.871 42.096-32.682 54.69-92.219 27.295-139.669-29.92-51.824-96.188-69.581-148.011-39.66-51.824 29.92-69.581 96.188-39.66 148.011 28.705 49.719 90.857 68.053 141.621 43.043 1.88 3.252 4.782 8.281 7.621 13.197-6.481 8.693-7.577 20.75-1.812 30.736 6.514 11.283 19.492 33.191 26.006 44.473 7.729 13.387 24.845 17.975 38.232 10.244 13.386-7.728 17.974-24.843 10.244-38.23" />
								{/* eslint-disable-next-line max-len */}
								<path d="M325.284 338.069c-3.861-7.283-9.04-14.025-15.522-19.861-12.595-11.34-28.08-17.418-43.775-18.414L265 315.927c14.271.927 28.196 7.267 38.421 18.824 3.028 3.422 5.55 7.116 7.597 10.983l14.266-7.665z" />
							</svg>
						</button>
					</div>
				) : null}
				<div className="answer" style={{ filter: !demo && !openAnswer ? "blur(7px)" : "none" }}>
					<div>
						<MathJax math={question.answer} />
					</div>
					{question.answer_image ? <img src={question.answer_image} alt="answer" /> : null}
				</div>
			</div>
			{openAnswer ? (
				<>
					<div className="explanation-wrap">
						<div className="explanation" dangerouslySetInnerHTML={{ __html: question.video_explanation }} />
					</div>
					{demo ? (
						<div className="buttons-container">
							<button
								type="button"
								disabled={Boolean(doneQuestionCount)}
								className="btn btn-primary"
								onClick={() => getStandard(lessonId)}
							>
								<span>Practice {questionsCount} questions</span>
							</button>
							{nextSectionLinkData.data && nextSectionLinkData.data.id ? (
								<Link
									to={
										nextSectionLinkData.type === "lesson group"
											? `/module/${nextSectionLinkData.data.module_id}`
											: `/${nextSectionLinkData.type}/${nextSectionLinkData.data.id}`
									}
									className="btn btn-primary-dark"
								>
									<span>
										Next {nextSectionLinkData.type}: {nextSectionLinkData.data.name}
									</span>
								</Link>
							) : null}
						</div>
					) : (
						<div className="response">
							<button
								onClick={() => toggleDone(question.id)}
								type="button"
								className="btn btn-primary-gradient"
							>
								<span>Got it!</span>
							</button>
							<Checkbox checked={question.done} readOnly />
						</div>
					)}
				</>
			) : null}
		</div>
	)
}

export default connect(
	null,
	{
		getStandard,
		toggleDone,
	},
)(QuestionItem)
