import { getAvailableCourses, getCourse } from "../../domain/course"

export const COURSES_SET = "COURSES_SET"
export const COURSES_REQUEST = "COURSES_REQUEST"
export const COURSES_REQUEST_DONE = "COURSES_REQUEST_DONE"
export const COURSES_SET_SELECTED = "COURSES_SET_SELECTED"
export const COURSES_CLEAR_SELECTED = "COURSES_CLEAR_SELECTED"
export const COURSES_REQUEST_SELECTED = "COURSES_REQUEST_SELECTED"
export const COURSES_REQUEST_SELECTED_DONE = "COURSES_REQUEST_SELECTED_DONE"

export const setCourses = data => ({ type: COURSES_SET, payload: data })

export const getCourses = () => dispatch => {
	return getAvailableCourses().then(res => {
		dispatch(setCourses(res.data.data))
		dispatch(coursesRequestDone())
		return res.data.data
	})
}

export const coursesRequest = () => ({ type: COURSES_REQUEST })

export const coursesRequestDone = () => ({ type: COURSES_REQUEST_DONE })

export const setSelectedCourse = data => ({ type: COURSES_SET_SELECTED, payload: data })

export const getSelectedCourse = id => dispatch => {
	getCourse(id).then(res => {
		dispatch(setSelectedCourse(res.data.data))
		dispatch(coursesRequestSelectedDone())
		return res.data.data
	})
}

export const clearSelectedCourse = () => ({ type: COURSES_CLEAR_SELECTED })

export const coursesRequestSelected = () => ({ type: COURSES_REQUEST_SELECTED })

export const coursesRequestSelectedDone = () => ({ type: COURSES_REQUEST_SELECTED_DONE })
