import React, { useEffect } from "react"
import { connect } from "react-redux"
import "./index.scss"
import { Button, Form } from "semantic-ui-react"
import { Field, reduxForm, SubmissionError, change } from "redux-form"
import { email, required, confirmation, length } from "redux-form-validators"
import { toast } from "react-toastify"
import { Input, Password } from "../../helpers/fields"
import { updateAuth } from "../../../domain/user"
import { setUser } from "../../../store/actions/authActions"
import { coursesRequestDone, setCourses } from "../../../store/actions/courseActions"
import { getAvailableCourses } from "../../../domain/course"
import ManageSubscriptionButton from "../../common/ManageSubscriptionButton"

const AccountEdit = ({ handleSubmit, setUser, initialValues, dispatch, courses, setCourses, coursesRequestDone }) => {
	useEffect(() => {
		getAvailableCourses().then(res => {
			setCourses(res.data.data)
			coursesRequestDone()
		})
	}, [])

	const _onSubmit = values => {
		return updateAuth(values)
			.then(res => {
				setUser(res.data.data)
				toast.success("Changes have been successfully saved!")
				resetPasswordFields()
			})
			.catch(error => {
				throw new SubmissionError(error.response.data.errors)
			})
	}

	const resetPasswordFields = () => {
		dispatch(change("accountEdit", "password", ""))
		dispatch(change("accountEdit", "password_confirmation", ""))
	}

	return (
		<div className="AccountEdit">
			<h1 className="screen-header">My Account</h1>
			<div className="form-wrapper">
				<div className="initials">{initialValues.first_name.charAt(0) + initialValues.last_name.charAt(0)}</div>
				{!courses.isFetching && courses.data.length && (
					<div className="courses-info">
						<div className="header">Your Courses</div>
						<ul className="courses-list">
							{courses.data.map(item => {
								return <li key={item.id}>{item.name}</li>
							})}
						</ul>
					</div>
				)}

				{!initialValues.trial_period && <ManageSubscriptionButton />}
				<Form className="form" onSubmit={handleSubmit(_onSubmit)}>
					<Field
						name="first_name"
						placeholder="First Name"
						component={Input}
						label="First Name"
						validate={[required()]}
					/>
					<Field
						name="last_name"
						placeholder="Last Name"
						component={Input}
						label="Last Name"
						validate={[required()]}
					/>
					<Field
						name="email"
						placeholder="Email"
						component={Input}
						label="Email"
						validate={[required(), email()]}
					/>
					<Field name="city" placeholder="City" component={Input} label="City" />
					<Field
						placeholder="Password"
						name="password"
						component={Password}
						label="Password"
						validate={[length({ min: 6, allowBlank: true })]}
					/>
					<Field
						name="password_confirmation"
						placeholder="Confirm Password"
						component={Password}
						label="Confirm Password"
						validate={[confirmation({ field: "password", fieldLabel: "Password" })]}
					/>
					<Button type="submit" className="btn btn-primary-gradient">
						Save
					</Button>
				</Form>
			</div>
		</div>
	)
}

let AccountEditStateForm = reduxForm({
	form: "accountEdit",
})(AccountEdit)

export default connect(
	({ authReducer, courseReducer }) => ({
		initialValues: authReducer.data.user,
		courses: courseReducer,
	}),
	{
		setUser,
		setCourses,
		coursesRequestDone,
	},
)(AccountEditStateForm)
