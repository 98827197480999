import React from "react"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { GuestRoute, PrivateRoute } from "./helpers/routes"
import Login from "./pages/Login"
import Buy from "./pages/Buy"
import PasswordEmail from "./pages/PasswordEmail"
import PasswordReset from "./pages/PasswordReset"
import PersonalAccountContainer from "./containers/PersonalAccountContainer"
import "normalize.css"
import "semantic-ui-css/semantic.min.css"
import "../styles/main.scss"
import "react-toastify/dist/ReactToastify.css"

toast.configure({
	position: toast.POSITION.BOTTOM_RIGHT,
	autoClose: 3000,
})

const App = ({ user }) => {
	return (
		<div className="App">
			<Router>
				<Switch>
					<GuestRoute exact loggedIn={user} path="/login" component={Login} />
					<GuestRoute exact loggedIn={user} path="/password/email" component={PasswordEmail} />
					<GuestRoute exact loggedIn={user} path="/password/reset/:token" component={PasswordReset} />

					<GuestRoute exact loggedIn={user} path="/buy/:id" component={Buy} />
					<GuestRoute exact loggedIn={user} path="/buy/:id/trial" component={Buy} />

					<PrivateRoute exact loggedIn={user} path="/:type?/:id?" component={PersonalAccountContainer} />
				</Switch>
			</Router>
		</div>
	)
}

export default connect(state => ({
	user: state.authReducer.data.user,
}))(App)
