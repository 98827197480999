import React, { useEffect, useState } from "react"
import "../index.scss"
import { connect } from "react-redux"
import { Grid } from "semantic-ui-react"
import { getFullVersionsForTrial } from "../../../../domain/product"
import { setUser } from "../../../../store/actions/authActions"
import ProductItem from "./ProductItem"

const Info = () => {
	const [products, setProducts] = useState(null)

	async function fetchData() {
		const response = await getFullVersionsForTrial()
		setProducts(response.data.data)
	}

	useEffect(() => {
		fetchData()
	}, [])

	if (!products) return null

	return (
		<div className="Upgrade">
			<div className="title">You are at the end of your free trial!</div>
			<div className="sub-title">To upgrade to the full version please choose from the below packages.</div>
			<Grid centered>
				{products.map((product, index) => (
					<Grid.Column kkey={product.id} computer={4} mobile={16}>
						<ProductItem {...product} key={product.id} index={index + 1} />
					</Grid.Column>
				))}
			</Grid>
		</div>
	)
}

export default connect(
	null,
	{ setUser },
)(Info)
