import React from "react"
import Slider from "react-slick"
import "./index.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from "react-router-dom"
import ProgressedTile from "../ProgressedTile"
import Tile from "../Tile"

const GroupedLessons = ({ group, firstColor, secondColor, textColor, progressColor, bgImage }) => {
	const settings = {
		speed: 200,
		infinite: false,
		arrows: false,
		dots: false,
		swipeToSlide: true,
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 723,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	}

	return (
		<div className="group-wrapper">
			<h2>{group.name}</h2>
			<Slider {...settings}>
				{group.lessons.map(item => (
					<Link
						className={item.disabled ? "disabled-link" : ""}
						key={item.id}
						to={`/lesson/${item.id}`}
						onClick={e => item.disabled && e.preventDefault()}
					>
						<div className="tile-wrap">
							{item.done_questions_count ? (
								<ProgressedTile
									title={item.name}
									questionsCount={item.questions_count}
									questionsDone={item.done_questions_count}
									progress={item.progress}
									bgImage={bgImage}
									firstColor={firstColor}
									secondColor={secondColor}
									progressColor={progressColor}
									textColor={textColor}
								/>
							) : (
								<Tile
									title={item.name}
									disabled={item.disabled}
									firstColor={firstColor}
									secondColor={secondColor}
									textColor={textColor}
									calculator={item.calculator}
									level={item.level}
									bgImage={bgImage}
								/>
							)}
						</div>
					</Link>
				))}
			</Slider>
		</div>
	)
}

export default GroupedLessons
