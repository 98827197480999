import { createReducer, isFetched, isFetching } from "../../utils/reducer"
import {
	PRODUCTS_REQUEST,
	PRODUCTS_REQUEST_DONE,
	PRODUCTS_SET,
	PRODUCT_TO_PURCHASE_SET,
	DISCOUNT_DATA_SET,
} from "../actions/productActions"

const initialState = {
	isFetching: true,
	data: [],
	productForPurchase: null,
	discount: {},
}

export const setProducts = (state, data) => {
	return { ...state, data: data }
}

export const setProductForPurchase = (state, data) => {
	return { ...state, productForPurchase: data }
}

export const setDiscountData = (state, discount) => {
	return { ...state, discount }
}

export default createReducer(initialState, {
	[PRODUCTS_SET]: setProducts,
	[DISCOUNT_DATA_SET]: setDiscountData,
	[PRODUCT_TO_PURCHASE_SET]: setProductForPurchase,
	[PRODUCTS_REQUEST]: isFetching,
	[PRODUCTS_REQUEST_DONE]: isFetched,
})
