import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { compose } from "redux"
import { Grid } from "semantic-ui-react"
import Tile from "../../common/Tile"
import { clearSelectedCourse, getSelectedCourse } from "../../../store/actions/courseActions"
import Breadcrumbs from "../../common/Breadcrumbs"
import "./index.scss"

const Modules = ({ course, getSelectedCourse, clearSelectedCourse, match }) => {
	useEffect(() => {
		getSelectedCourse(match.params.id)

		return () => {
			clearSelectedCourse()
		}
	}, [])

	if (course.isFetching) return null

	let hasGroups = Boolean(course.data.modules.filter(item => item.has_groups)[0])

	return (
		<>
			<Breadcrumbs items={[{ path: `/course/${course.id}`, label: course.data.name }]} />
			<div className="Module tile-wrapper">
				<h1 className="screen-header">{course.data.name}</h1>
				<Grid centered>
					{course.isFetching
						? null
						: course.data.modules.map(item => (
								<Grid.Column key={item.id} computer={5} mobile={16}>
									<Link key={item.id} to={`/module/${item.id}`}>
										<Tile
											title={hasGroups ? item.name : course.data.name}
											subtitle={hasGroups ? null : item.name}
											firstColor={course.data.tint_color}
											secondColor={course.data.tint_color_second}
											textColor={course.data.text_colour}
											bgImage={course.data.background}
											grouped={hasGroups}
										/>
									</Link>
								</Grid.Column>
						  ))}
				</Grid>
			</div>
		</>
	)
}

export default compose(
	connect(
		({ courseReducer: { selectedCourse } }) => ({
			course: selectedCourse,
		}),
		{
			getSelectedCourse,
			clearSelectedCourse,
		},
	),
)(Modules)
