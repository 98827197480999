import { combineReducers } from "redux"
import { reducer as form } from "redux-form"
import authReducer from "./authReducer"
import courseReducer from "./courseReducer"
import moduleReducer from "./moduleReducer"
import lessonReducer from "./lessonReducer"
import productReducer from "./productReducer"

export default combineReducers({
	form,
	authReducer,
	courseReducer,
	moduleReducer,
	lessonReducer,
	productReducer,
})
