import React from "react"
import { withRouter } from "react-router"
import { PropTypes } from "prop-types"
import WMModal from "../../common/WMModal"

const SuccessfulSignUpModal = ({ open, history, trialProduct }) => {
	return (
		<WMModal open={open} onButtonClick={() => history.push("/")} buttonText="Go to courses">
			<h3>Thank you</h3>
			<p>
				{!trialProduct ? (
					<>
						Your payment has been setup.
						<br />
					</>
				) : null}
				You now have access to your courses.
			</p>
		</WMModal>
	)
}

SuccessfulSignUpModal.propTypes = {
	open: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	trialProduct: PropTypes.bool.isRequired,
}

export default withRouter(SuccessfulSignUpModal)
