import React, { useEffect } from "react"
import { Redirect, Switch } from "react-router"
import { connect } from "react-redux"
import { Container } from "semantic-ui-react"
import { PrivateRoute } from "../../helpers/routes"
import Courses from "../../pages/Courses"
import Module from "../../pages/Modules"
import Upgrade from "../../pages/Upgrade/Info"
import UpgradeForm from "../../pages/Upgrade/Form"
import Congrats from "../../pages/Upgrade/Congrats"
import Lesson from "../../pages/Lessons"
import Questions from "../../pages/Questions"
import AccountEdit from "../../pages/AccountEdit"
import TrailBanner from "../../common/TrialBanner"
import Header from "../../common/Header"
import "./index.scss"
import { me } from "../../../store/actions/authActions"
import SubscriptionInvalidModal from "../../common/SubscriptionInvalidModal"

const PersonalAccountContainer = ({
	user,
	showUpgradeSuccessfulPage,
	me,
	match: {
		params: { type = "home" },
	},
}) => {
	useEffect(() => {
		me()
	}, [me])

	return (
		<div className={`PersonalAccountContainer ${type}`}>
			<Container>
				<Header />
			</Container>
			{user.trial_period && type !== "upgrade" && <TrailBanner />}
			{!user.trial_period && !user.is_subscription_active && <SubscriptionInvalidModal />}
			<Container>
				<Switch>
					<PrivateRoute exact loggedIn={user} path="/" component={Courses} />
					<PrivateRoute exact loggedIn={user} path="/course/:id" component={Module} />
					<PrivateRoute exact loggedIn={user} path="/module/:id" component={Lesson} />
					<PrivateRoute exact loggedIn={user} path="/lesson/:id" component={Questions} />
					<PrivateRoute exact loggedIn={user} path="/account/edit" component={AccountEdit} />
					{user.trial_period && (
						<Switch>
							<PrivateRoute exact loggedIn={user} path="/upgrade" component={Upgrade} />
							<PrivateRoute exact loggedIn={user} path="/upgrade/:id" component={UpgradeForm} />
						</Switch>
					)}
					{showUpgradeSuccessfulPage && (
						<PrivateRoute exact loggedIn={user} path="/upgrade/congrats" component={Congrats} />
					)}
					<Redirect to="/" />
				</Switch>
			</Container>
		</div>
	)
}

export default connect(
	state => ({
		user: state.authReducer.data.user,
		showUpgradeSuccessfulPage: state.authReducer.showUpgradeSuccessfulPage,
	}),
	{
		me,
	},
)(PersonalAccountContainer)
