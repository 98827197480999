import React, { useMemo } from "react"
import "./index.scss"
import ProgressRing from "../ProgressRing"

const ProgressedTile = ({
	title,
	questionsCount,
	questionsDone,
	progress,
	firstColor,
	secondColor,
	textColor,
	bgImage,
	progressColor,
}) => {
	firstColor = firstColor ? firstColor : "#690986"
	secondColor = secondColor ? secondColor : "#690986"
	textColor = textColor ? textColor : "#ffffff"

	const styles = useMemo(() => {
		const result = {}

		if (bgImage) {
			result.backgroundImage = `url("${bgImage}")`
		}

		return result
	})

	return (
		<div className="Tile ProgressedTile" style={styles}>
			<div
				className="gradient"
				style={{
					background: `linear-gradient(270deg, ${secondColor} 0%, ${firstColor} 100%)`,
				}}
			/>
			<div
				className="text"
				style={{
					color: textColor,
				}}
			>
				<h2>{title}</h2>
				<h3>{`${questionsDone} of ${questionsCount}`}</h3>
			</div>
			<ProgressRing
				progress={progress}
				radius={45}
				stroke={4}
				textColor={textColor}
				progressColor={progressColor}
			/>
		</div>
	)
}

export default ProgressedTile
