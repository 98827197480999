import React from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { signOut } from "../../../store/actions/authActions"
import "./index.scss"
import logo from "../../../static/img/logo_pos.png"

const Header = ({ signOut }) => {
	return (
		<div className="Header">
			<Link to="/">
				<img className="logo" src={logo} alt="logo" />
			</Link>
			<div className="actions">
				<Link to="/account/edit">
					<FontAwesomeIcon className="user" icon={faUser} />
				</Link>
				<FontAwesomeIcon className="logout" onClick={signOut} icon={faSignOutAlt} />
			</div>
		</div>
	)
}

export default connect(
	null,
	{ signOut },
)(Header)
