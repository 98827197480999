import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import "./index.scss"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const Breadcrumbs = ({ items }) => {
	return (
		<div className="Breadcrumbs">
			<Link to="/">
				<span>Your Courses</span>
			</Link>
			{items.map(item => (
				<React.Fragment key={item.path}>
					<FontAwesomeIcon icon={faAngleRight} />
					<Link to={item.path}>
						<span className="bold">{item.label}</span>
					</Link>
				</React.Fragment>
			))}
		</div>
	)
}

Breadcrumbs.propTypes = {
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Breadcrumbs
