import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import moment from "moment"

export const PrivateRoute = ({ component: Component, location, loggedIn, ...rest }) => {
	if (!loggedIn) return <Redirect to="/login" />

	if (
		loggedIn.trial_period &&
		(moment(loggedIn.trial_ends_at).isBefore(moment()) && !location.pathname.includes("upgrade"))
	)
		return <Redirect to={`/upgrade/${loggedIn.product_id}`} />

	if (!loggedIn.trial_period && !loggedIn.is_subscription_active && !location.pathname.includes("account")) {
		return <Redirect to="/account/edit" />
	}

	return <Route {...rest} render={props => <Component {...props} />} />
}

export const GuestRoute = ({ component: Component, ...rest }) => (
	<Route {...rest} render={props => (rest.loggedIn ? <Redirect to="/" /> : <Component {...props} />)} />
)

PrivateRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	path: PropTypes.string.isRequired,
}

GuestRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	path: PropTypes.string.isRequired,
}
