import React from "react"
import "./index.scss"
import logo from "../../../static/img/logo_pos.png"

const ResetPasswordLayout = ({ children }) => {
	return (
		<div className="ResetPasswordLayout">
			<img className="logo" src={logo} alt="Logo" />
			<div className="content">{children}</div>
		</div>
	)
}

export default ResetPasswordLayout
