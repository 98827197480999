import { createStore, compose, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import Reducers from "./reducers"
import Middleware from "./middleware"
import request from "../utils/request"

const storeEnhancers = applyMiddleware(...Middleware, thunk)
const composedEnhancer = process.env.NODE_ENV !== "production" ? composeWithDevTools(storeEnhancers) : storeEnhancers
const Store = createStore(Reducers, {}, compose(composedEnhancer))

let currentAccount = localStorage.getItem("account")

Store.subscribe(() => {
	const state = Store.getState()
	let nextAccount = state.authReducer ? state.authReducer.data : null

	if (currentAccount !== nextAccount && !state.authReducer.isFetching) {
		request.setAuthToken(nextAccount.token)
		currentAccount = nextAccount
	}
})

export default Store
