import axios from "axios"
import _ from "lodash"
import { toast } from "react-toastify"
import Store from "../store"
import { signOut, toggleSingeDeviceNotification } from "../store/actions/authActions"

// Axios default config
axios.defaults.baseURL = process.env.REACT_APP_API_PATH
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.patch["Content-Type"] = "application/json"
axios.defaults.headers.delete["Content-Type"] = "application/json"

axios.interceptors.response.use(
	function(response) {
		return response
	},
	function(error) {
		if (error.request.status === 401) {
			if (error.response.data.message === "The token has been blacklisted")
				Store.dispatch(toggleSingeDeviceNotification())

			Store.dispatch(signOut())
		}
		if (error.response.status >= 500) {
			toast.error(error.response.data.message, {
				autoClose: false,
			})
		}
		return Promise.reject(error)
	},
)

const get = (url, data = {}, params = {}) => {
	return axios.get(url + serialize(data), params)
}

const post = (url, data = {}) => {
	return axios.post(url, data)
}

const put = (url, data = {}) => {
	return axios.put(url, data)
}

const del = url => {
	return axios.delete(url)
}

const serialize = obj => {
	// return obj && _.isPlainObject(obj) ?
	return !_.isEmpty(obj)
		? "?" +
				Object.keys(obj)
					.reduce(function(array, key) {
						array.push(key + "=" + encodeURIComponent(obj[key]))
						return array
					}, [])
					.join("&")
		: ""
}

const setAuthToken = token => {
	axios.defaults.headers.common["Authorization"] = "Bearer " + token
}

const accountData = JSON.parse(localStorage.getItem("account"))
const token = accountData ? accountData.token : null
setAuthToken(token)

const request = {
	get,
	post,
	put,
	delete: del,
	setAuthToken,
}

export default request
