import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import * as serviceWorker from "./serviceWorker"
import Store from "./store"
import App from "./components"

const AppWrap = () => (
	<Provider store={Store}>
		<App />
	</Provider>
)

ReactDOM.render(<AppWrap />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
