import { createReducer, isFetched, isFetching } from "../../utils/reducer"
import {
	AUTH_REQUEST,
	AUTH_REQUEST_DONE,
	SET_USER,
	SIGN_IN,
	SIGN_OUT,
	TOGGLE_SINGLE_DEVICE_NOTIFICATION,
	TOGGLE_SHOW_UPGRADE_SUCCESSFUL_PAGE,
} from "../actions/authActions"
import request from "../../utils/request"

const initialState = {
	isFetching: false,
	showSingleDeviceNotification: false,
	showUpgradeSuccessfulPage: false,
	data: JSON.parse(localStorage.getItem("account")) || [],
}

export const setUser = (state, data) => {
	const newState = { ...state, data: { ...state.data, user: data } }
	localStorage.setItem("account", JSON.stringify(newState.data))
	return newState
}

export const signIn = (state, data) => {
	localStorage.setItem("account", JSON.stringify(data))
	request.setAuthToken(data.token)
	return { ...state, data: data }
}

export const signOut = state => {
	localStorage.removeItem("account")
	return { ...state, data: {} }
}

export const toggleSingeDeviceNotification = state => {
	return { ...state, showSingleDeviceNotification: !state.showSingleDeviceNotification }
}

export const toggleShowUpgradeSuccessfulPage = state => {
	return { ...state, showUpgradeSuccessfulPage: !state.showUpgradeSuccessfulPage }
}

export default createReducer(initialState, {
	[SIGN_IN]: signIn,
	[SIGN_OUT]: signOut,
	[SET_USER]: setUser,
	[AUTH_REQUEST]: isFetching,
	[AUTH_REQUEST_DONE]: isFetched,
	[TOGGLE_SINGLE_DEVICE_NOTIFICATION]: toggleSingeDeviceNotification,
	[TOGGLE_SHOW_UPGRADE_SUCCESSFUL_PAGE]: toggleShowUpgradeSuccessfulPage,
})
