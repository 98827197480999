import { createReducer, isFetched, isFetching } from "../../utils/reducer"
import {
	COURSES_CLEAR_SELECTED,
	COURSES_REQUEST,
	COURSES_REQUEST_DONE,
	COURSES_REQUEST_SELECTED,
	COURSES_REQUEST_SELECTED_DONE,
	COURSES_SET,
	COURSES_SET_SELECTED,
} from "../actions/courseActions"

const initialState = {
	isFetching: true,
	data: [],
	selectedCourse: {
		isFetching: true,
		data: {},
	},
}

export const setCourses = (state, data) => {
	return { ...state, data: data }
}

export const setSelectedCourse = (state, data) => {
	const newState = { ...state }
	newState.selectedCourse.data = data
	return newState
}

export const clearSelectedCourse = state => {
	const newState = { ...state }
	newState.selectedCourse.isFetching = true
	newState.selectedCourse.data = {}
	return newState
}

export const isSelectedFetching = state => {
	return { ...state, selectedCourse: { ...state.selectedCourse, isFetching: true } }
}

export const isSelectedFetched = state => {
	return { ...state, selectedCourse: { ...state.selectedCourse, isFetching: false } }
}

export default createReducer(initialState, {
	[COURSES_SET]: setCourses,
	[COURSES_REQUEST]: isFetching,
	[COURSES_REQUEST_DONE]: isFetched,
	[COURSES_SET_SELECTED]: setSelectedCourse,
	[COURSES_CLEAR_SELECTED]: clearSelectedCourse,
	[COURSES_REQUEST_SELECTED]: isSelectedFetching,
	[COURSES_REQUEST_SELECTED_DONE]: isSelectedFetched,
})
