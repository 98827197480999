export const createReducer = (initialState, fnMap) => (state = initialState, { type, payload }) => {
	const handler = fnMap[type]
	return handler ? handler(state, payload) : state
}

export const isFetching = state => {
	return { ...state, isFetching: true }
}

export const isFetched = state => {
	return { ...state, isFetching: false }
}
