import request from "../utils/request"

export const getProduct = productId => request.get(`/products/${productId}`)

export const getTrialProduct = productId => request.get(`/products/${productId}/trial`)

export const getDiscountData = (productId, discountCode) =>
	request.get(`/products/${productId}/discount`, { discount_code: discountCode })

export const getFullVersionsForTrial = () => request.get("/trial/full-versions")

export const getProductsForUpgrade = () => request.get("/products/upgrade")
