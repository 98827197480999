import React, { useEffect } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import Breadcrumbs from "../../common/Breadcrumbs"
import { clearLesson, clearStandard, getLesson, getStandard } from "../../../store/actions/lessonActions"
import QuestionItem from "../../common/QuestionItem"
import "./index.scss"

const Questions = ({ lesson, standard, getLesson, clearStandard, getStandard, clearLesson, match }) => {
	useEffect(() => {
		getLesson(match.params.id)

		return () => {
			clearStandard()
			clearLesson()
		}
	}, [match.params.id])

	useEffect(() => {
		if (!lesson.isFetching && !lesson.data.demo_question) getStandard(match.params.id)
	}, [lesson.isFetching])

	if (lesson.isFetching) return null

	return (
		<>
			<Breadcrumbs
				items={[
					{ path: `/course/${lesson.data.module.course.id}`, label: lesson.data.module.course.name },
					{ path: `/module/${lesson.data.module.id}`, label: lesson.data.module.name },
					{ path: `/lesson/${lesson.data.id}`, label: lesson.data.name },
				]}
			/>
			<div className="Questions">
				<div className="questions-wrap">
					{lesson.data.demo_question ? (
						<QuestionItem
							title={lesson.data.demo_question.title || "Demo Question"}
							questionsCount={lesson.data.questions_count}
							doneQuestionCount={lesson.data.done_questions_count}
							question={lesson.data.demo_question}
							lessonId={lesson.data.id}
							demo
						/>
					) : null}
					{!standard.isFetching && standard.data.length
						? standard.data.map((item, index) => (
								<QuestionItem
									key={item.id}
									title={item.title || `Question ${index + 1}`}
									question={item}
								/>
						  ))
						: null}
				</div>
			</div>
		</>
	)
}

export default compose(
	connect(
		({ lessonReducer }) => ({
			lesson: lessonReducer.lesson,
			standard: lessonReducer.standard,
		}),
		{
			getLesson,
			getStandard,
			clearStandard,
			clearLesson,
		},
	),
)(Questions)
