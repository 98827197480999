import { createReducer, isFetched, isFetching } from "../../utils/reducer"
import {
	MODULES_CLEAR_SELECTED,
	MODULES_REQUEST,
	MODULES_REQUEST_DONE,
	MODULES_REQUEST_SELECTED,
	MODULES_REQUEST_SELECTED_DONE,
	MODULES_SET,
	MODULES_SET_SELECTED,
} from "../actions/moduleActions"

const initialState = {
	isFetching: true,
	data: [],
	selectedModule: {
		isFetching: true,
		data: {},
	},
}

export const setModules = (state, data) => {
	return { ...state, data: data }
}

export const setSelectedModule = (state, data) => {
	const newState = { ...state }
	newState.selectedModule.data = data
	return newState
}

export const clearSelectedCourse = state => {
	const newState = { ...state }
	newState.selectedModule.isFetching = true
	newState.selectedModule.data = {}
	return newState
}

export const isSelectedFetching = state => {
	return { ...state, selectedModule: { ...state.selectedModule, isFetching: true } }
}

export const isSelectedFetched = state => {
	return { ...state, selectedModule: { ...state.selectedModule, isFetching: false } }
}

export default createReducer(initialState, {
	[MODULES_SET]: setModules,
	[MODULES_REQUEST]: isFetching,
	[MODULES_REQUEST_DONE]: isFetched,
	[MODULES_SET_SELECTED]: setSelectedModule,
	[MODULES_CLEAR_SELECTED]: clearSelectedCourse,
	[MODULES_REQUEST_SELECTED]: isSelectedFetching,
	[MODULES_REQUEST_SELECTED_DONE]: isSelectedFetched,
})
