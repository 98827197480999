import React from "react"
import { Field, reduxForm, SubmissionError } from "redux-form"
import { required, email, length, confirmation } from "redux-form-validators"
import { Button, Form } from "semantic-ui-react"
import { compose } from "redux"
import { Link } from "react-router-dom"
import { Error, Input, Password } from "../../helpers/fields"
import { passwordReset } from "../../../domain/auth"
import ResetPasswordLayout from "../../containers/ResetPasswordLayout"
import "./index.scss"

const PasswordReset = ({ handleSubmit, match, history }) => {
	const _onSubmit = values => {
		return passwordReset({ ...values, token: match.params.token })
			.then(() => {
				history.push("/login")
			})
			.catch(err => {
				throw new SubmissionError(err.response.data.errors)
			})
	}

	return (
		<div className="PasswordReset">
			<ResetPasswordLayout>
				<h1>Forgot Password</h1>
				<Form className="form" onSubmit={handleSubmit(_onSubmit)}>
					<Field name="error" component={Error} />
					<Field
						name="email"
						placeholder="Email"
						component={Input}
						label="Email"
						validate={[required(), email()]}
					/>
					<Field
						placeholder="Password"
						name="password"
						component={Password}
						label="Password"
						validate={[required(), length({ min: 8 })]}
					/>
					<Field
						name="password_confirmation"
						placeholder="Confirm Password"
						component={Password}
						label="Confirm Password"
						validate={[required(), confirmation({ field: "password", fieldLabel: "Password" })]}
					/>
					<div className="actions-wrapper">
						<Button type="submit" className="btn btn-primary-gradient">
							Submit
						</Button>
						<Link to="/login">Back to Sign In</Link>
					</div>
				</Form>
			</ResetPasswordLayout>
		</div>
	)
}

export default compose(reduxForm({ form: "passwordReset" }))(PasswordReset)
