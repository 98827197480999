import React from "react"
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { compose } from "redux"
import { Field, reduxForm, SubmissionError } from "redux-form"
import { confirmation, email, required } from "redux-form-validators"
import { PropTypes } from "prop-types"
import { connect } from "react-redux"
import { Button } from "semantic-ui-react"
import { Input, Password } from "../../helpers/fields"
import { authRequest, authRequestDone, signIn } from "../../../store/actions/authActions"
import { signUp } from "../../../domain/auth"
import PaymentForm from "../../common/PaymentForm"

const formName = "signUp"

const SignUpForm = ({
	handleSubmit,
	match,
	signIn,
	authRequestDone,
	authRequest,
	auth,
	setModalOpen,
	trialProduct,
}) => {
	const elements = useElements()
	const stripe = useStripe()

	const submit = async data => {
		authRequest()

		if (!trialProduct) {
			const cardElement = elements.getElement(CardNumberElement)

			const paymentMethod = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
			})

			if (paymentMethod.error) {
				authRequestDone()
				throw new SubmissionError({
					payment: paymentMethod.error.message,
				})
			}

			const {
				paymentMethod: { id },
			} = paymentMethod

			data.payment = { id }
		}

		data.trial = trialProduct

		return signUp(match.params.id, data)
			.then(res => {
				setModalOpen(true)
				signIn(res.data)
				authRequestDone()
				return res
			})
			.catch(err => {
				authRequestDone()
				throw new SubmissionError(err.response.data.errors)
			})
	}

	return (
		<>
			<form onSubmit={handleSubmit(submit)}>
				<h3>1. Your Details</h3>
				<div className="row">
					<Field name="first_name" component={Input} label="First Name" validate={[required()]} />
					<Field name="last_name" component={Input} label="Last Name" validate={[required()]} />
				</div>
				<div className="row">
					<Field name="email" component={Input} label="Email" validate={[required(), email()]} />
					<Field name="password" component={Password} label="Password" validate={[required()]} />
				</div>
				<div className="row">
					<Field
						name="password_confirmation"
						component={Password}
						label="Confirm Password"
						validate={[required(), confirmation({ field: "password", fieldLabel: "Password" })]}
					/>
				</div>
				{!trialProduct && <PaymentForm form={formName} title="2. Payment details" />}
				<Button className="btn btn-primary-gradient" loading={auth.isFetching}>
					{trialProduct ? "Sign up" : "Pay now"}
				</Button>
			</form>
		</>
	)
}

SignUpForm.propTypes = {
	trialProduct: PropTypes.bool.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	signIn: PropTypes.func.isRequired,
	authRequestDone: PropTypes.func.isRequired,
	authRequest: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	setModalOpen: PropTypes.func.isRequired,
}

export default compose(
	connect(
		({ authReducer }) => ({
			auth: authReducer,
		}),
		{
			signIn,
			authRequestDone,
			authRequest,
		},
	),
	reduxForm({ form: formName }),
)(SignUpForm)
