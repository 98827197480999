import React from "react"
import { Checkbox as CheckboxUI, Input as InputUI } from "semantic-ui-react"

export const Input = ({ input, disabled = false, label, placeholder, meta: { touched, error } }) => (
	<div className="input-wrapper">
		<div className="info">
			<label>{label}</label>
			{touched && error && <span className="validation-error">{error}</span>}
		</div>
		<input {...input} disabled={disabled} placeholder={placeholder} type="text" />
	</div>
)
export const SInput = ({ input, label, placeholder, meta: { touched, error }, ...rest }) => (
	<div className="input-wrapper">
		<div className="info">
			<label>{label}</label>
			{touched && error && <span className="validation-error">{error}</span>}
		</div>
		<InputUI {...input} {...rest} />
	</div>
)

export const Password = ({ input, label, placeholder, meta: { touched, error } }) => (
	<div className="input-wrapper">
		<div className="info">
			<label>{label}</label>
			{touched && error && <span className="validation-error">{error}</span>}
		</div>
		<input {...input} placeholder={placeholder} type="password" />
	</div>
)

export const Checkbox = ({ input, label, meta: { touched, error } }) => {
	return (
		<div className={`checkbox-wrapper ${touched && error ? "error" : ""}`}>
			<div className="checkbox">
				<CheckboxUI {...input} placeholder={label} type="checkbox" value="" />
			</div>
			<label>{label}</label>
		</div>
	)
}

export const Error = ({ meta: { touched, error } }) =>
	touched && error ? <span className="validation-error">{error}</span> : <span />
