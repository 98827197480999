import React from "react"
import "./index.scss"
import { Field, reduxForm } from "redux-form"
import { required, email } from "redux-form-validators"
import { Button, Form } from "semantic-ui-react"
import { compose } from "redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Input } from "../../helpers/fields"
import { passwordEmail } from "../../../domain/auth"
import ResetPasswordLayout from "../../containers/ResetPasswordLayout"

const PasswordEmail = ({ handleSubmit }) => {
	const _onSubmit = values => {
		passwordEmail(values)
		toast.success("Reset password email has been sent!")
	}

	return (
		<div className="PasswordEmail">
			<ResetPasswordLayout>
				<h1>Forgot Password</h1>
				<p>Enter your Email address and we’ll send you a link to reset your password.</p>
				<Form className="form" onSubmit={handleSubmit(_onSubmit)}>
					<Field
						name="email"
						placeholder="Email"
						component={Input}
						label="Email"
						validate={[required(), email()]}
					/>
					<div className="actions-wrapper">
						<Button type="submit" className="btn btn-primary-gradient">
							Send Reset Link
						</Button>
						<Link to="/login">Back to Sign In</Link>
					</div>
				</Form>
			</ResetPasswordLayout>
		</div>
	)
}

export default compose(reduxForm({ form: "passwordEmail" }))(PasswordEmail)
