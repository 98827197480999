import { me as meRequest } from "../../domain/user"

export const SIGN_IN = "SIGN_IN"
export const SIGN_OUT = "SIGN_OUT"
export const SET_USER = "SET_USER"
export const AUTH_REQUEST = "AUTH_REQUEST"
export const AUTH_REQUEST_DONE = "AUTH_REQUEST_DONE"
export const TOGGLE_SINGLE_DEVICE_NOTIFICATION = "TOGGLE_SINGLE_DEVICE_NOTIFICATION"
export const TOGGLE_SHOW_UPGRADE_SUCCESSFUL_PAGE = "TOGGLE_SHOW_UPGRADE_SUCCESSFUL_PAGE"

export const me = () => dispatch =>
	meRequest().then(res => {
		dispatch(setUser(res.data.data))
		return res.data.data
	})

export const signIn = data => ({ type: SIGN_IN, payload: data })

export const setUser = data => ({ type: SET_USER, payload: data })

export const signOut = () => ({ type: SIGN_OUT })

export const authRequest = () => ({ type: AUTH_REQUEST })

export const authRequestDone = () => ({ type: AUTH_REQUEST_DONE })

export const toggleSingeDeviceNotification = () => ({ type: TOGGLE_SINGLE_DEVICE_NOTIFICATION })

export const toggleShowUpgradeSuccessfulPage = () => ({ type: TOGGLE_SHOW_UPGRADE_SUCCESSFUL_PAGE })
