import React, { useMemo } from "react"
import "./index.scss"

// source: https://css-tricks.com/building-progress-ring-quickly/
const ProgressRing = ({ radius, stroke, progress, textColor, progressColor }) => {
	const normalizedRadius = useMemo(() => radius - stroke * 2, [radius, stroke])
	const circumference = useMemo(() => normalizedRadius * 2 * Math.PI, [normalizedRadius, stroke])
	const strokeDashoffset = useMemo(() => circumference - (progress / 100) * circumference, [])

	return (
		<svg className="ProgressRing" height={radius * 2} width={radius * 2}>
			<circle
				stroke="white"
				fill="transparent"
				strokeWidth={stroke}
				strokeDasharray={`${circumference} ${circumference}`}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
			<circle
				className="progress"
				fill="transparent"
				strokeWidth={stroke}
				strokeDasharray={`${circumference} ${circumference}`}
				style={{ strokeDashoffset, stroke: progressColor }}
				r={normalizedRadius}
				cx={radius}
				cy={radius}
			/>
			<text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill={textColor}>
				{progress}%
			</text>
		</svg>
	)
}

export default ProgressRing
