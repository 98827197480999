import { getModule } from "../../domain/module"

export const MODULES_SET = "MODULES_SET"
export const MODULES_REQUEST = "MODULES_REQUEST"
export const MODULES_REQUEST_DONE = "MODULES_REQUEST_DONE"
export const MODULES_SET_SELECTED = "MODULES_SET_SELECTED"
export const MODULES_CLEAR_SELECTED = "MODULES_CLEAR_SELECTED"
export const MODULES_REQUEST_SELECTED = "MODULES_REQUEST_SELECTED"
export const MODULES_REQUEST_SELECTED_DONE = "MODULES_REQUEST_SELECTED_DONE"

export const setModules = data => ({ type: MODULES_SET, payload: data })

export const modulesRequest = () => ({ type: MODULES_REQUEST })

export const modulesRequestDone = () => ({ type: MODULES_REQUEST_DONE })

export const setSelectedModule = data => ({ type: MODULES_SET_SELECTED, payload: data })

export const getSelectedModule = id => dispatch => {
	getModule(id).then(res => {
		dispatch(setSelectedModule(res.data.data))
		dispatch(modulesRequestSelectedDone())
		return res.data.data
	})
}

export const clearSelectedModule = () => ({ type: MODULES_CLEAR_SELECTED })

export const modulesRequestSelected = () => ({ type: MODULES_REQUEST_SELECTED })

export const modulesRequestSelectedDone = () => ({ type: MODULES_REQUEST_SELECTED_DONE })
