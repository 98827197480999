import { getLessonWithDemo } from "../../domain/lesson"
import { getStandard as fetchStandard, toggleDone as toggleDoneRequest } from "../../domain/question"

export const LESSONS_SET_SELECTED = "LESSONS_SET_SELECTED"
export const LESSONS_CLEAR_LESSON = "LESSONS_CLEAR_LESSON"

export const LESSONS_SET_STANDARD = "LESSONS_SET_STANDARD"
export const LESSONS_CHANGE_DONE = "LESSONS_CHANGE_DONE"
export const LESSONS_CLEAR_STANDARD = "LESSONS_CLEAR_STANDARD"

export const LESSONS_SELECTED_REQUEST = "LESSONS_SELECTED_REQUEST"
export const LESSONS_SELECTED_REQUEST_DONE = "LESSONS_SELECTED_REQUEST_DONE"

export const LESSONS_STANDARD_REQUEST = "LESSONS_STANDARD_REQUEST"
export const LESSONS_STANDARD_REQUEST_DONE = "LESSONS_STANDARD_REQUEST_DONE"

export const getLesson = id => dispatch => {
	return getLessonWithDemo(id).then(res => {
		dispatch(setLesson(res.data.data))
		dispatch(lessonSelectedRequestDone())
		return res.data.data
	})
}
export const setLesson = data => ({ type: LESSONS_SET_SELECTED, payload: data })
export const clearLesson = () => ({ type: LESSONS_CLEAR_LESSON })

export const getStandard = id => dispatch => {
	return fetchStandard(id).then(res => {
		dispatch(setStandard(res.data.data))
		dispatch(lessonStandardRequestDone())
		return res.data.data
	})
}
export const setStandard = data => ({ type: LESSONS_SET_STANDARD, payload: data })
export const toggleDone = id => dispatch => {
	dispatch(changeDone(id))
	toggleDoneRequest(id).catch(() => {
		dispatch(changeDone(id))
	})
}
export const changeDone = questionId => ({ type: LESSONS_CHANGE_DONE, payload: questionId })
export const clearStandard = () => ({ type: LESSONS_CLEAR_STANDARD })

export const lessonSelectedRequest = () => ({ type: LESSONS_SELECTED_REQUEST })
export const lessonSelectedRequestDone = () => ({ type: LESSONS_SELECTED_REQUEST_DONE })

export const lessonStandardRequest = () => ({ type: LESSONS_STANDARD_REQUEST })
export const lessonStandardRequestDone = () => ({ type: LESSONS_STANDARD_REQUEST_DONE })
