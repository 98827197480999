import * as _ from "lodash"
import { createReducer } from "../../utils/reducer"
import {
	LESSONS_SET_SELECTED,
	LESSONS_SELECTED_REQUEST,
	LESSONS_SELECTED_REQUEST_DONE,
	LESSONS_SET_STANDARD,
	LESSONS_STANDARD_REQUEST,
	LESSONS_STANDARD_REQUEST_DONE,
	LESSONS_CHANGE_DONE,
	LESSONS_CLEAR_STANDARD,
	LESSONS_CLEAR_LESSON,
} from "../actions/lessonActions"

const initialState = {
	lesson: {
		isFetching: true,
		data: {},
	},
	standard: {
		isFetching: true,
		data: [],
	},
}

export const setLesson = (state, data) => {
	return { ...state, lesson: { ...state.lesson, data: data } }
}

export const clearLesson = state => {
	const newState = _.cloneDeep(state)
	newState.lesson.isFetching = true
	newState.lesson.data = {}

	return newState
}

export const setStandard = (state, data) => {
	return { ...state, standard: { ...state.standard, data: data } }
}

export const changeDone = (state, questionId) => {
	const newState = _.cloneDeep(state)
	newState.standard.data.map(item => {
		if (item.id === questionId) item.done = !item.done
	})

	return newState
}

export const clearStandard = state => {
	const newState = _.cloneDeep(state)
	newState.lesson.isFetching = true
	newState.standard.data = []

	return newState
}

export const isLessonFetching = state => {
	return { ...state, lesson: { ...state.lesson, isFetching: true } }
}
export const isLessonFetched = state => {
	return { ...state, lesson: { ...state.lesson, isFetching: false } }
}

export const isStandardFetching = state => {
	return { ...state, standard: { ...state.standard, isFetching: true } }
}
export const isStandardFetched = state => {
	return { ...state, standard: { ...state.standard, isFetching: false } }
}

export default createReducer(initialState, {
	[LESSONS_SET_SELECTED]: setLesson,
	[LESSONS_CLEAR_LESSON]: clearLesson,

	[LESSONS_SET_STANDARD]: setStandard,
	[LESSONS_CLEAR_STANDARD]: clearStandard,
	[LESSONS_CHANGE_DONE]: changeDone,

	[LESSONS_SELECTED_REQUEST]: isLessonFetching,
	[LESSONS_SELECTED_REQUEST_DONE]: isLessonFetched,

	[LESSONS_STANDARD_REQUEST]: isStandardFetching,
	[LESSONS_STANDARD_REQUEST_DONE]: isStandardFetched,
})
