import {
	getProduct,
	getProductsForUpgrade,
	getDiscountData as getDiscountDataRequest,
	getTrialProduct,
} from "../../domain/product"

export const PRODUCTS_SET = "PRODUCTS_SET"
export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST"
export const PRODUCTS_REQUEST_DONE = "PRODUCTS_REQUEST_DONE"
export const PRODUCT_TO_PURCHASE_SET = "PRODUCT_TO_PURCHASE_SET"
export const DISCOUNT_DATA_SET = "DISCOUNT_DATA_SET"

export const setProducts = data => ({ type: PRODUCTS_SET, payload: data })

export const getProducts = () => dispatch => {
	return getProductsForUpgrade().then(res => {
		dispatch(setProducts(res.data.data))
		dispatch(productsRequestDone())
		return res.data.data
	})
}

export const setProductForPurchase = data => ({ type: PRODUCT_TO_PURCHASE_SET, payload: data })

export const getProductForPurchase = (id, trial = false) => dispatch => {
	const request = trial ? getTrialProduct : getProduct

	return request(id).then(res => {
		dispatch(setProductForPurchase(res.data.data))
		dispatch(productsRequestDone())
		return res.data.data
	})
}

export const resetDiscountData = () => ({ type: DISCOUNT_DATA_SET, payload: {} })

export const setDiscountData = data => ({ type: DISCOUNT_DATA_SET, payload: data })

export const getDiscountData = (productId, discountCode) => dispatch => {
	return getDiscountDataRequest(productId, discountCode)
		.then(res => {
			dispatch(setDiscountData(res.data.data))
			return res.data.data
		})
		.catch(err => {
			if (err.response.data.errors) {
				dispatch(
					setDiscountData({
						error: err.response.data.errors.discount_code[0],
					}),
				)
			} else {
				throw err
			}
		})
}

export const productsRequest = () => ({ type: PRODUCTS_REQUEST })

export const productsRequestDone = () => ({ type: PRODUCTS_REQUEST_DONE })
