import React from "react"
import WMModal from "../WMModal"
import ManageSubscriptionButton from "../ManageSubscriptionButton"

const SubscriptionInvalidModal = () => {
	return (
		<WMModal open button={<ManageSubscriptionButton />}>
			<p>Your account does not have an active subscription. Click below to manage your subscription.</p>
		</WMModal>
	)
}

export default SubscriptionInvalidModal
