import React from "react"
import { useSelector } from "react-redux"
import "./index.scss"
import { formatPrice } from "../../../utils/money"

const PaymentSummaryBox = () => {
	const product = useSelector(state => state.productReducer.productForPurchase)
	const discount = useSelector(state => state.productReducer.discount)

	if (!product) return null

	return (
		<div className="summary-wrapper">
			<div className="title">Order Summary</div>
			<table>
				<thead>
					<tr>
						<th>Item</th>
						<th>Price</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{product.name}</td>
						<td>{formatPrice(product.price)}</td>
					</tr>
					{discount.id && (
						<tr>
							<td>Discount ({discount.name})</td>
							<td>-{formatPrice(discount.amount_off)}</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className="total">
				<div className="label">Total</div>
				<div className="amount">{formatPrice(product.price - (discount.amount_off || 0))}</div>
			</div>
		</div>
	)
}

export default PaymentSummaryBox
