import React from "react"
import "./index.scss"
import { Button, Modal } from "semantic-ui-react"
import logoIcon from "../../../static/img/logo_icon.png"

const WMModal = ({ button, buttonText, onButtonClick, children, ...rest }) => {
	return (
		<Modal {...rest} dimmer="inverted" size="tiny">
			<Modal.Content>
				<img src={logoIcon} alt="logo" />
				{children}
				{button ? (
					button
				) : (
					<Button className="btn btn-secondary-dark" onClick={onButtonClick}>
						{buttonText}
					</Button>
				)}
			</Modal.Content>
		</Modal>
	)
}

export default WMModal
