import React from "react"
import "./index.scss"
import { Field, reduxForm, SubmissionError } from "redux-form"
import { required, email, length } from "redux-form-validators"
import { Button, Form } from "semantic-ui-react"
import { compose } from "redux"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Checkbox, Input, Password, Error } from "../../helpers/fields"
import { authRequest, authRequestDone, signIn } from "../../../store/actions/authActions"
import { login } from "../../../domain/auth"
import logoPositive from "../../../static/img/logo_pos.png"
import logoNegative from "../../../static/img/logo_neg.png"
import icon from "../../../static/img/logo_icon.png"
import SingleDeviceNotification from "./SingleDeviceNotification"

const Login = ({ handleSubmit, signIn, authRequestDone, authRequest, showSingleDeviceNotification }) => {
	const _onSubmit = values => {
		authRequest()
		return login(values)
			.then(res => {
				signIn(res.data)
				authRequestDone()
			})
			.catch(err => {
				authRequestDone()
				throw new SubmissionError(err.response.data.errors)
			})
	}

	return (
		<div className="login-wrapper">
			<div className="Login">
				<div className="content-wrap">
					<img className="logo" src={logoPositive} alt="Logo" />
					<div className="form-wrapper">
						<h1 className="title">Login</h1>
						<div className="register-suggestion">
							<span>Need an account?</span>
							<a href="https://www.sarahplumley.com/memberships/">Create an account</a>
						</div>
						<Form className="form" onSubmit={handleSubmit(_onSubmit)}>
							<Field name="auth" component={Error} />
							<Field name="email" component={Input} label="Email" validate={[required(), email()]} />
							<Field
								name="password"
								component={Password}
								label="Password"
								validate={[required(), length({ min: 6 })]}
							/>
							<Button type="submit" className="btn btn-primary-gradient">
								Login
							</Button>
							<div className="bottom-content">
								<Field name="remember_me" component={Checkbox} label="Keep me logged in" value="true" />
								<Link to="/password/email">Forgot Password</Link>
							</div>
						</Form>
					</div>
				</div>
			</div>
			<div className="background">
				<img className="icon" src={icon} alt="icon" />
				<img className="logo" src={logoNegative} alt="logo" />
			</div>
			{showSingleDeviceNotification && <SingleDeviceNotification open={showSingleDeviceNotification} />}
		</div>
	)
}

export default compose(
	reduxForm({ form: "login" }),
	connect(
		({ authReducer: { showSingleDeviceNotification } }) => ({
			showSingleDeviceNotification,
		}),
		{
			signIn,
			authRequestDone,
			authRequest,
		},
	),
)(Login)
