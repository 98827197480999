import React, { useCallback, useState } from "react"
import { Button } from "semantic-ui-react"
import { billingPortal } from "../../../domain/user"
import "./index.scss"

const ManageSubscriptionButton = () => {
	const [billingPortalLoading, setBillingPortalLoading] = useState(false)

	const onManageSubscriptionClick = useCallback(() => {
		setBillingPortalLoading(true)

		billingPortal()
			.then(res => {
				window.location.href = res.data
			})
			.finally(() => {
				setBillingPortalLoading(false)
			})
	}, [])

	return (
		<Button
			className="btn btn-secondary-dark manage-subscription"
			onClick={onManageSubscriptionClick}
			loading={billingPortalLoading}
		>
			Manage Subscription
		</Button>
	)
}

export default ManageSubscriptionButton
