import React, { useMemo } from "react"
import { Container } from "semantic-ui-react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import "./index.scss"
import moment from "moment"

const TrailBanner = ({ user }) => {
	const message = useMemo(() => `You are on trial until ` + moment(user.trial_ends_at).format("DD/MM/YYYY"), [user])

	return (
		<div className="TrailBanner">
			<Container>
				<div className="content-wrap">
					<div className="text">{message}</div>
					<Link to={`/upgrade/${user.product_id}`} className="btn">
						Upgrade
					</Link>
				</div>
			</Container>
		</div>
	)
}

export default connect(state => ({
	user: state.authReducer.data.user,
}))(TrailBanner)
