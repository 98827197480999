import React, { useEffect, useMemo, useState } from "react"
import "./index.scss"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Grid } from "semantic-ui-react"
import { connect } from "react-redux"
import { matchPath } from "react-router"
import SignUpForm from "./SignUpForm"
import SuccessfulSignUpModal from "./SuccessfulSignUpModal"
import logo from "../../../static/img/logo_pos.png"
import { getProductForPurchase } from "../../../store/actions/productActions"
import PaymentSummaryBox from "../../common/PaymentSummaryBox"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Buy = ({ match, user, history, product, getProductForPurchase }) => {
	const [modalOpen, setModalOpen] = useState(false)

	const isTrial = useMemo(
		() =>
			!!matchPath(match.url, {
				path: "/buy/:id/trial",
				exact: true,
				strict: true,
			}),
		[],
	)

	useEffect(() => {
		if (user && user.trial_period && !modalOpen) history.push("/")
		else getProductForPurchase(match.params.id, isTrial)
	}, [match.params.id, isTrial])

	if (!product) return null

	return (
		<div className="buy-bg">
			<div className="SignUp">
				<h1>Purchase {product.name}</h1>
				<Grid container reversed="computer">
					<Grid.Column column={1} only="computer" />
					<Grid.Column mobile={16} computer={5}>
						<div className="info-wrapper">
							{product.description ? (
								<div className="desc">
									<h2>What is {product.name}?</h2>
									<div dangerouslySetInnerHTML={{ __html: product.description }} />
								</div>
							) : null}
							<PaymentSummaryBox />
							<div className="logo">
								<img src={logo} alt="logo" />
							</div>
						</div>
					</Grid.Column>
					<Grid.Column column={1} only="computer" />
					<Grid.Column mobile={16} computer={8}>
						<div className="form">
							<Elements stripe={stripePromise}>
								<SignUpForm
									match={match}
									history={history}
									modalOpen={modalOpen}
									setModalOpen={setModalOpen}
									trialProduct={isTrial}
								/>
							</Elements>
							{modalOpen && <SuccessfulSignUpModal open={modalOpen} trialProduct={isTrial} />}
						</div>
					</Grid.Column>
					<Grid.Column column={1} only="computer" />
				</Grid>
			</div>
		</div>
	)
}

export default connect(
	({ authReducer, productReducer }) => ({
		user: authReducer.data.user,
		product: productReducer.productForPurchase,
	}),
	{
		getProductForPurchase,
	},
)(Buy)
