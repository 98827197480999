import React from "react"
import { connect } from "react-redux"
import WMModal from "../../common/WMModal"
import { toggleSingeDeviceNotification } from "../../../store/actions/authActions"

const SingleDeviceNotification = ({ open, toggleSingeDeviceNotification }) => {
	return (
		<WMModal open={open} onButtonClick={toggleSingeDeviceNotification} buttonText="Close">
			<p>
				Sorry you have been logged out!
				<br />
				You can only log into your account from one device at a time.
				<br />
				{/* eslint-disable-next-line react/no-unescaped-entities */}
				If you're seeing this message it's because we have detected another login from a different device.
			</p>
		</WMModal>
	)
}

export default connect(
	null,
	{
		toggleSingeDeviceNotification,
	},
)(SingleDeviceNotification)
