import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { compose } from "redux"
import { Grid } from "semantic-ui-react"
import Tile from "../../common/Tile"
import { clearSelectedModule, getSelectedModule } from "../../../store/actions/moduleActions"
import GroupedLessons from "../../common/GroupedLessons"
import ProgressedTile from "../../common/ProgressedTile"
import Breadcrumbs from "../../common/Breadcrumbs"
import "./index.scss"

const Lessons = ({ module, getSelectedModule, clearSelectedModule, match }) => {
	useEffect(() => {
		getSelectedModule(match.params.id)

		return () => {
			clearSelectedModule()
		}
	}, [])

	if (module.isFetching) return null

	return (
		<>
			<Breadcrumbs
				items={[
					{ path: `/course/${module.data.course.id}`, label: module.data.course.name },
					{ path: `/module/${module.data.id}`, label: module.data.name },
				]}
			/>
			<div className="Lessons">
				<h1 className="screen-header" style={{ fontSize: module.data.has_groups ? "30px" : "24px" }}>
					{module.data.name}
				</h1>
				{module.isFetching ? null : !module.data.has_groups ? (
					<Grid>
						{module.data.lessons.map(item => (
							<Grid.Column key={item.id} computer={4} mobile={16}>
								<Link
									className={item.disabled ? "disabled-link" : ""}
									key={item.id}
									to={`/lesson/${item.id}`}
									onClick={e => item.disabled && e.preventDefault()}
								>
									{item.done_questions_count ? (
										<ProgressedTile
											title={item.name}
											questionsCount={item.questions_count}
											questionsDone={item.done_questions_count}
											progress={item.progress}
											firstColor={module.data.course.tint_color}
											secondColor={module.data.course.tint_color_second}
											textColor={module.data.course.text_colour}
											progressColor={module.data.course.progress_colour}
											bgImage={module.data.course.background}
										/>
									) : (
										<Tile
											title={item.name}
											disabled={item.disabled}
											firstColor={module.data.course.tint_color}
											secondColor={module.data.course.tint_color_second}
											textColor={module.data.course.text_colour}
											bgImage={module.data.course.background}
											calculator={item.calculator}
											level={item.level}
										/>
									)}
								</Link>
							</Grid.Column>
						))}
					</Grid>
				) : (
					module.data.lessonGroups.map(item => (
						<GroupedLessons
							key={item.id}
							group={item}
							firstColor={module.data.course.tint_color}
							secondColor={module.data.course.tint_color_second}
							textColor={module.data.course.text_colour}
							progressColor={module.data.course.progress_colour}
							bgImage={module.data.course.background}
						/>
					))
				)}
			</div>
		</>
	)
}

export default compose(
	connect(
		({ moduleReducer: { selectedModule } }) => ({
			module: selectedModule,
		}),
		{
			getSelectedModule,
			clearSelectedModule,
		},
	),
)(Lessons)
