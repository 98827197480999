import React from "react"
import { Link } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import InjectedForm from "./Form"
import "./index.scss"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const Form = () => (
	<div className="Upgrade Form">
		<div className="title">You&apos;re Almost There</div>
		<div className="sub-title">
			To upgrade to the full version please fill form below or <Link to="/upgrade">change plan</Link>
		</div>
		<Elements stripe={stripePromise}>
			<InjectedForm />
		</Elements>
	</div>
)

export default Form
